import { useEffect, useRef, useState } from "react";

const Submit = ({children, className, isSubmitting, ...rest}) => {
    const [dots, setDots] = useState(".");
    const intervalRef = useRef(null);
    useEffect(() => {
        if (isSubmitting) {
          intervalRef.current = setInterval(() => {
            setDots((prev) => {
              if (prev === "...") return ".";
              return prev + ".";
            });
          }, 500);
        } else {
          clearInterval(intervalRef.current);
          setDots(".");
        }
        return () => clearInterval(intervalRef.current);
    }, [isSubmitting]);    

    return (
        <button type="submit" className={className} disabled={isSubmitting} {...rest}>{isSubmitting?<p style={{minWidth:"24px"}} className="min-w-6">{dots}</p>:children}</button>
    )
}

export default Submit;
